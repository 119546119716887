import Paramawati1 from '../../../../media/kbp/Paramawati/Paramawati (1).webp'
import Paramawati2 from '../../../../media/kbp/Paramawati/Paramawati (2).webp'
import Paramawati3 from '../../../../media/kbp/Paramawati/Paramawati (3).webp'
import Paramawati4 from '../../../../media/kbp/Paramawati/Paramawati (4).webp'
import Paramawati5 from '../../../../media/kbp/Paramawati/Paramawati (5).webp'
import Paramawati6 from '../../../../media/kbp/Paramawati/Paramawati (6).webp'
import Paramawati7 from '../../../../media/kbp/Paramawati/Paramawati (7).webp'
import Paramawati8 from '../../../../media/kbp/Paramawati/Paramawati (8).webp'
import Paramawati9 from '../../../../media/kbp/Paramawati/Paramawati (9).webp'
import Paramawati10 from '../../../../media/kbp/Paramawati/Paramawati (10).webp'
import Paramawati11 from '../../../../media/kbp/Paramawati/Paramawati (11).webp'
import Paramawati12 from '../../../../media/kbp/Paramawati/Paramawati (12).webp'



const Paramawatiimages =[
Paramawati1,Paramawati2,Paramawati3,Paramawati4,Paramawati5,Paramawati6,Paramawati7,Paramawati8,Paramawati9,Paramawati10,Paramawati11,Paramawati12
]

export default Paramawatiimages;