import Fasilitas1 from '../../../media/Fasilitas/Fasilitas (1).webp'
import Fasilitas2 from '../../../media/Fasilitas/Fasilitas (2).webp'
import Fasilitas3 from '../../../media/Fasilitas/Fasilitas (3).webp'
import Fasilitas4 from '../../../media/Fasilitas/Fasilitas (4).webp'
import Fasilitas5 from '../../../media/Fasilitas/Fasilitas (5).webp'
import Fasilitas6 from '../../../media/Fasilitas/Fasilitas (6).webp'
import Fasilitas7 from '../../../media/Fasilitas/Fasilitas (7).webp'
import Fasilitas8 from '../../../media/Fasilitas/Fasilitas (8).webp'





const fasilitasimages =[
    Fasilitas1,Fasilitas2,Fasilitas3,Fasilitas4,Fasilitas5,Fasilitas6,Fasilitas7,Fasilitas8
]

export default fasilitasimages;

