import Surrounding1 from '../../../media/Surrounding Area/Surrounding Area (1).webp'
import Surrounding2 from '../../../media/Surrounding Area/Surrounding Area (2).webp'
import Surrounding3 from '../../../media/Surrounding Area/Surrounding Area (3).webp'
import Surrounding4 from '../../../media/Surrounding Area/Surrounding Area (4).webp'
import Surrounding5 from '../../../media/Surrounding Area/Surrounding Area (5).webp'
import Surrounding6 from '../../../media/Surrounding Area/Surrounding Area (6).webp'





const Surroundingimages =[
    Surrounding1,Surrounding2,Surrounding3,Surrounding4,Surrounding5,Surrounding6
]

export default Surroundingimages;