import React from "react";
import "./about.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import logokbp from "../../../media/logo-kbp.png";

const aboutkbp = () => {
  const aboutwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6283110558510&text=Halo%20Dimas,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Kota%20Baru%20Parahyangan%20https://kbp-kotabaruparahyangan.id//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  return (
    <div className="aboutkbp">
      <div id="about" className="container-aboutkbp">
        <div className="heading-aboutkbp">
          <div className="logokbp">
            <img className="gambarkbp" src={logokbp} alt="logo-kbp" />
          </div>
          <div className="judulkbp">An Aesthetic Living</div>
          <div className="desk">
            Perumahan Kota Bumi Parahyangan, salah satu proyek perumahan
            terpadu, terletak di lokasi strategis menghadap ke arah barat daya
            Bandung, perumahan ini menawarkan pemandangan alam yang memukau,
            terutama matahari terbenam yang menambah nilai estetika dan
            kenyamanan tinggal. Perumahan ini dibangun dengan konsep modern yang
            selaras dengan alam dan menawarkan berbagai fasilitas dan keuntungan
            bagi penghuninya. Menyediakan berbagai fasilitas lengkap seperti
            taman bermain anak, lapangan olahraga, kolam renang, pusat
            kebugaran, dan area terbuka hijau untuk rekreasi keluarga.
            Mengutamakan pengelolaan lingkungan yang berkelanjutan dengan
            mengintegrasikan teknologi ramah lingkungan, pengelolaan air, dan
            penghijauan yang memadai.
          </div>
          <div className="button">
            <button className="wa" onClick={aboutwa}>
              <FontAwesomeIcon icon={faWhatsapp} />
              &nbsp; More Info
            </button>
          </div>
        </div>
        <div className="gambaraboutkbp"></div>
      </div>
    </div>
  );
};

export default aboutkbp;
