import React from "react";
import "./newlaunching1.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowsUpDownLeftRight} from "@fortawesome/free-solid-svg-icons";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import {faBed} from "@fortawesome/free-solid-svg-icons";
import {faShower} from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import Asihimages from "./Asih.js";
import Asriimages from "./Asri.js";

const Produk1 = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const Asri = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6283110558510&text=Halo%20Dimas,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Kota%20Baru%20Parahyangan%20(Wisesa%20Asri)%20https://kbp-kotabaruparahyangan.id//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const Asih = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6283110558510&text=Halo%20Dimas,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Kota%20Baru%20Parahyangan%20(Wisesa%20Asih)%20https://kbp-kotabaruparahyangan.id//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  return (
    <div className="container">
      <div className="containercard2">
        <div className="card">
          <Slider {...settings}>
            {Asriimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="judulkartu">
              <div className="nama-rumah">Wisesa Asri</div>
              <span>Soft DP</span>
            </div>
            <div className="cicilan">
              <div className="angka">21</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <hr color="black" />
          <div className="gridspek">
            <FontAwesomeIcon
              color="rgb(132, 132, 132)"
              icon={faArrowsUpDownLeftRight}
            />
            <span> : 180m²</span>
            <FontAwesomeIcon color="rgb(132, 132, 132)" icon={faHouse} />
            <span> : 220m²</span>
            <FontAwesomeIcon color="rgb(132, 132, 132)" icon={faBed} />
            <span>: 3</span>
            <FontAwesomeIcon color="rgb(132, 132, 132)" icon={faShower} />
            <span>: 2</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Asri} className="whatsapp">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Asihimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="judulkartu">
              <div className="nama-rumah">Wisesa Asih</div>
              <span>Soft DP</span>
            </div>
            <div className="cicilan">
              <div className="angka">22</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <hr color="black" />
          <div className="gridspek">
            <FontAwesomeIcon
              color="rgb(132, 132, 132)"
              icon={faArrowsUpDownLeftRight}
            />
            <span> : 180m²</span>
            <FontAwesomeIcon color="rgb(132, 132, 132)" icon={faHouse} />
            <span> : 205m²</span>
            <FontAwesomeIcon color="rgb(132, 132, 132)" icon={faBed} />
            <span>: 3</span>
            <FontAwesomeIcon color="rgb(132, 132, 132)" icon={faShower} />
            <span>: 2</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Asih} className="whatsapp">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Produk1;
