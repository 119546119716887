import React from "react";
import "./home.scss";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ScrollToHashElement from "./ScrollToHashElement.js";
import Header from "../../Pages/section/header/header.jsx";
import Banner from "../section/Banner/banner.jsx";
import About from "../section/About/about.jsx";
import Promo from "../section/Promo/promo.jsx";
import Fasilitas from "../section/Fasilitas/fasilitas.jsx";
import Produk from "../section/produk/produk.jsx";
import Surrounding from "../section/Surrounding Area/surrounding.jsx";
import Lokasi from "../section/Lokasi/lokasi.jsx";
import Footer from "../section/Footer/footer.jsx";
import Newlaunching from "../section/Newlaunching/newlaunching.jsx";
import tombol from "../../media/tombol.webp";

const home = () => {
  const fungsiganteng = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6283110558510&text=Halo%20Dimas,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Kota%20Baru%20Parahyanganh%20https://kbp-kotabaruparahyangan.id//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  return (
    <div className="home">
      <ScrollToHashElement />
      <Header />
      <Banner />
      <About />
      <Newlaunching />
      <Promo />
      <Fasilitas />
      <Produk />
      <Surrounding />
      <Lokasi />
      <Footer />
      <img
        onClick={fungsiganteng}
        src={tombol}
        className="tombolwa"
        alt="Hubungi Sekarang!"
      />
      <div className="wamobile">
        <button onClick={fungsiganteng} className="mobile">
          <div>
            <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp Sekarang
          </div>
        </button>
      </div>
    </div>
  );
};

export default home;
