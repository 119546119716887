import React from "react";
import "./header.scss";
import Logo from "../../../media/logo-kbp.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";

const headerkbp = () => {
  const waheader = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6283110558510&text=Halo%20Dimas,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Kota%20Baru%20Parahyangan%20%20https://kbp-kotabaruparahyangan.id//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="container-headerkbp">
      <div className="logokbp">
        <img className="gambarkbp" src={Logo} alt="logo-kbp" />
      </div>
      <div className="container-buttonwa">
        <button className="button" onClick={waheader}>
          <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
        </button>
      </div>
    </div>
  );
};

export default headerkbp;
