import Sima1 from '../../../../media/kbp/Sima.webp'
import Sima2 from '../../../../media/kbp/Sima.webp'




const Simaimages =[
    Sima1, Sima2
]

export default Simaimages;