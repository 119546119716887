import Nilapadmi1 from '../../../../media/kbp/Nilapadmi/Nilapadmi (3).webp'
import Nilapadmi2 from '../../../../media/kbp/Nilapadmi/Nilapadmi (4).webp'
import Nilapadmi3 from '../../../../media/kbp/Nilapadmi/Nilapadmi (5).webp'
import Nilapadmi4 from '../../../../media/kbp/Nilapadmi/Nilapadmi (6).webp'
import Nilapadmi5 from '../../../../media/kbp/Nilapadmi/Nilapadmi (7).webp'
import Nilapadmi6 from '../../../../media/kbp/Nilapadmi/Nilapadmi (8).webp'
import Nilapadmi7 from '../../../../media/kbp/Nilapadmi/Nilapadmi (9).webp'
import Nilapadmi8 from '../../../../media/kbp/Nilapadmi/Nilapadmi (10).webp'
import Nilapadmi9 from '../../../../media/kbp/Nilapadmi/Nilapadmi (11).webp'
import Nilapadmi10 from '../../../../media/kbp/Nilapadmi/Nilapadmi (12).webp'
import Nilapadmi11 from '../../../../media/kbp/Nilapadmi/Nilapadmi (13).webp'
import Nilapadmi12 from '../../../../media/kbp/Nilapadmi/Nilapadmi (14).webp'
import Nilapadmi13 from '../../../../media/kbp/Nilapadmi/Nilapadmi (15).webp'
import Nilapadmi14 from '../../../../media/kbp/Nilapadmi/Nilapadmi (16).webp'
import Nilapadmi15 from '../../../../media/kbp/Nilapadmi/Nilapadmi (17).webp'

const Nilapadmiimages =[
Nilapadmi1,Nilapadmi2,Nilapadmi3,Nilapadmi4,Nilapadmi5,Nilapadmi6,Nilapadmi7,Nilapadmi8,Nilapadmi9,Nilapadmi10,Nilapadmi11,Nilapadmi12,Nilapadmi13,Nilapadmi14,Nilapadmi15
]

export default Nilapadmiimages;