import React from "react";
import "./produk1.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Paramawatiimages from "./Paramawati.js";
import Tarubhawanaimages from "./Tarubhawana.js";
import Nilapadmiimages from "./Nilapadmi.js";
import Simaimages from "./Sima.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowsUpDownLeftRight} from "@fortawesome/free-solid-svg-icons";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import {faBed} from "@fortawesome/free-solid-svg-icons";
import {faShower} from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";

const Produk1 = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const Tarukejora = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6283110558510&text=Halo%20Dimas,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Kota%20Baru%20Parahyangan%20(Tarukejora)%20https://kbp-kotabaruparahyangan.id//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const Simakirana = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6283110558510&text=Halo%20Dimas,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Kota%20Baru%20Parahyangan%20(Simakirana)%20https://kbp-kotabaruparahyangan.id//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const Nilapadmi = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6283110558510&text=Halo%20Dimas,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Kota%20Baru%20Parahyangan%20(Nilapiadmi)%20https://kbp-kotabaruparahyangan.id//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const Paramawati = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6283110558510&text=Halo%20Dimas,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Kota%20Baru%20Parahyangan%20(Paramawati)%20https://kbp-kotabaruparahyangan.id//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  return (
    <div className="container">
      <div className="containercard1">
        <div className="card">
          <Slider {...settings}>
            {Tarubhawanaimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="judulkartu">
              <div className="nama-rumah">Tatar Tarukejora</div>
              <span>Soft DP</span>
            </div>
            <div className="cicilan">
              <div className="angka">11</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <hr color="black" />
          <div className="gridspek">
            <FontAwesomeIcon
              color="rgb(132, 132, 132)"
              icon={faArrowsUpDownLeftRight}
            />
            <span> : 120m²</span>
            <FontAwesomeIcon color="rgb(132, 132, 132)" icon={faHouse} />
            <span> : 97m²</span>
            <FontAwesomeIcon color="rgb(132, 132, 132)" icon={faBed} />
            <span>: 3</span>
            <FontAwesomeIcon color="rgb(132, 132, 132)" icon={faShower} />
            <span>: 3</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Tarukejora} className="whatsapp">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Simaimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="judulkartu">
              <div className="nama-rumah">Tatar Simakirana</div>
              <span>Soft DP</span>
            </div>
            <div className="cicilan">
              <div className="angka">13</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <hr color="black" />
          <div className="gridspek">
            <FontAwesomeIcon
              color="rgb(132, 132, 132)"
              icon={faArrowsUpDownLeftRight}
            />
            <span> : 160m²</span>
            <FontAwesomeIcon color="rgb(132, 132, 132)" icon={faHouse} />
            <span> : 97m²</span>
            <FontAwesomeIcon color="rgb(132, 132, 132)" icon={faBed} />
            <span>: 3</span>
            <FontAwesomeIcon color="rgb(132, 132, 132)" icon={faShower} />
            <span>: 3</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Simakirana} className="whatsapp">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Nilapadmiimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="judulkartu">
              <div className="nama-rumah">Tatar Nilapadmi</div>
              <span>Soft DP</span>
            </div>
            <div className="cicilan">
              <div className="angka">28</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <hr color="black" />
          <div className="gridspek">
            <FontAwesomeIcon
              color="rgb(132, 132, 132)"
              icon={faArrowsUpDownLeftRight}
            />
            <span> : 290m²</span>
            <FontAwesomeIcon color="rgb(132, 132, 132)" icon={faHouse} />
            <span> : 265m²</span>
            <FontAwesomeIcon color="rgb(132, 132, 132)" icon={faBed} />
            <span>: 4+1</span>
            <FontAwesomeIcon color="rgb(132, 132, 132)" icon={faShower} />
            <span>: 4+1</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Nilapadmi} className="whatsapp">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Paramawatiimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="judulkartu">
              <div className="nama-rumah">Tatar Paramawati</div>
              <span>Soft DP</span>
            </div>
            <div className="cicilan">
              <div className="angka">36</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <hr color="black" />
          <div className="gridspek">
            <FontAwesomeIcon
              color="rgb(132, 132, 132)"
              icon={faArrowsUpDownLeftRight}
            />
            <span> : 375m²</span>
            <FontAwesomeIcon color="rgb(132, 132, 132)" icon={faHouse} />
            <span> : 275m²</span>
            <FontAwesomeIcon color="rgb(132, 132, 132)" icon={faBed} />
            <span>: 4+1</span>
            <FontAwesomeIcon color="rgb(132, 132, 132)" icon={faShower} />
            <span>: 4+1</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Paramawati} className="whatsapp">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Produk1;
