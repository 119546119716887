import Tarubhawana1 from '../../../../media/kbp/Tarubhawana/Tarubhawana (1).webp'
import Tarubhawana2 from '../../../../media/kbp/Tarubhawana/Tarubhawana (2).webp'
import Tarubhawana3 from '../../../../media/kbp/Tarubhawana/Tarubhawana (3).webp'
import Tarubhawana4 from '../../../../media/kbp/Tarubhawana/Tarubhawana (4).webp'



const Tarubhawanaimages =[
Tarubhawana1,Tarubhawana2,Tarubhawana3,Tarubhawana4
]

export default Tarubhawanaimages;