import React from "react";
import "./banner.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import bannerimages from "./banner.js";

const Banner = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768, // Adjust breakpoint for your mobile size
        settings: {
          slidesToShow: 1, // Show only 1 slide on mobile screens
        },
      },
    ],
  };
  return (
    <div className="kartu">
      <div>
        <Slider {...settings}>
          {bannerimages.map((image, index) => (
            <div>
              <img className="banner" key={index} src={image} alt="banner" />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Banner;
